/**
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .userWayContainer {
    position: absolute;
    right: 0;
  }
  
  .userWayBackGroundBody {
    cursor: pointer;
    background: transparent !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 3px !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
    box-sizing: content-box !important;
  }
  
  .userWayBackGroundBody:hover {
    transition: transform .4s !important;
    transform: scale(1.25) !important;}
  
  @media only screen and (max-width: 991.7px) {
    .userWayContainer {
        margin-top: 1rem !important;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .userWayContainer {
        margin-top: -6.5rem !important;
    }
  
  }