/* body, html {
    height: 100%;
    background-image: url(./images/background.jpg);
    z-index: -1;
    filter: blur(6px);
    -webkit-filter: blur(6px);
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
.modal-content{
  margin-top: 5rem;
}
body{
  margin-top: -8px!important;
}
.bg-text {
  /* background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4);
    color: white; */
  border: 3px solid #f1f1f1;
  /* font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; */
  width: 70%;
  padding: 20px;
  text-align: justify;
}

.fixated .jumbotron {
  margin-bottom: 0;
}

.right-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  color: unset;
}

.carousel-item img {
  height: 399px;
}

.gallery_hyperlink {
  color: #d05e1b;
  cursor: pointer;
}

.gallery_hyperlink:hover {
  color: #00781d;
}
@media (min-width: 992px) and (max-width: 1100px) {
  body{
    margin-top: -10px!important;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  body{
    margin-top: -11px!important;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  body{
    margin-top: -12px!important;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  body{
    margin-top: -13px!important;
  }
}
@media (min-width: 1400px)  and (max-width: 1999px){
  body{
    margin-top: -16px!important;
  }
}
@media (min-width: 2000px)and (max-width:2500px){
  body{
    margin-top: -20px!important;
  }
}
@media (min-width: 2500px) {
  body{
    margin-top: -27px!important;
  }
}
@media (max-width: 991px) {
  .bg-text {
    border: 3px solid #f1f1f1;

    width: 90%;
    padding: 20px;
    text-align: justify;
  }
}

a {
  background-color: transparent;
  color: #007bff !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}